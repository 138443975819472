import Network from 'lib/api/network';
import Random from 'lib/random';
import _ from 'lodash';
import useClientState from 'composables/client-state';

const { getQueryParam } = useClientState();

export default {
  namespaced: true,

  state() {
    return {
      currentUserTimeZone: null,
      timeZones: null,
      startDate: null,
      tags: [],
      flowType: null,
      roles: [],
      pathTag: null,
      defaultSession: {
        startDate: null,
        tagIds: [],
        memberships: [],
        timeZone: { label: 'Import from Slack', value: 'sync' },
      },
      sessions: [],
    };
  },

  getters: {
    involvedTags(state) {
      const tagIds = _.uniq(_.flatMap(state.sessions.map(session => session.tagIds)));
      return state.tags.filter(tag => tagIds.includes(tag.id));
    },

    involvedRoles(state, getters) {
      const roleIds = _.uniq(_.flatMap(getters.involvedTags.map(tag => tag.roleIds)));
      return state.roles.filter(role => roleIds.includes(role.id));
    },

    tagsForSession: (state) => (session) => (session || state.defaultSession).tagIds.map(id => state.tags.find(t => t.id === id)),

    findRole: (state) => ({ id, purpose }) => state.roles.find((role) => {
      if (id) return role.id === id;
      return role.purpose === purpose;
    }),

    importTimeZoneOption() {
      return { label: 'Import from Slack', value: 'sync' };
    },

    basePath(state) {
      // This is the base of all paths, with no query params. It includes flowType and tag path params, if applicable.
      // We use this primarily to calculate the other re-routing paths and to hit the upsert endpoint with the correct flowType and tag param
      return `/${state.flowType.routeResource}${state.pathTag ? `/tags/${state.pathTag.id}` : ''}`;
    },

    batchesPath(_state, getters) {
      // This is the batches path, which is the base path plus '/batches'.
      // We use this to re-route back to the batches index of the applicable basePath once the upsert is successful
      return `${getters.basePath}/batches`;
    },

    backPath(_state, getters) {
      // This is the 'back' path that we apply to the breadcrumbs and to the Cancel button.
      // It will often just be the same as the batches path, but will add a :batch_id if coming from batches show instead of batches index
      const batchId = getQueryParam('batch_id');
      if (batchId) return `${getters.batchesPath}/${batchId}`;
      return getters.batchesPath;
    },

    roleSelectionArgs: (_state) => (selectorRecord) => {
      if (selectorRecord.type === 'OnboardingRole') {
        return {
          selector_role_id: selectorRecord.id,
          placeholder: `Selected by ${selectorRecord.name}`,
          role: selectorRecord,
        };
      } else if (selectorRecord.type === 'User') {
        return {
          selector_id: selectorRecord.id,
          placeholder: `Selected by ${selectorRecord.name}`,
          user: selectorRecord,
        };
      } else if (selectorRecord.type === 'Channel') {
        return {
          channel_id: selectorRecord.id,
          placeholder: `Selected from ${selectorRecord.name}`,
          channel: selectorRecord,
        };
      }
    },

    // TODO: This is only used below in upsertSessions, should I move this elsewhere?
    formData: (state) => {
      const sessionsData = state.sessions.map(session => {
        const memberships = session.memberships.map(memb => {
          const membData = {
            role_id: memb.role.id,
            user_id: memb.user?.id,
            email: memb.email,
            first_name: memb.first_name,
            last_name: memb.last_name,
            personal_email: memb.personal_email,
          };
          // Remove falsy values. Not really necessary, but keeps things a little simpler.
          Object.keys(membData).forEach((key) => { if (!membData[key]) delete membData[key]; });
          if (memb.role_selection) {
            membData.role_selection = {};
            ['selector_id', 'selector_role_id', 'channel_id'].forEach((key) => {
              membData.role_selection[key] = memb.role_selection[key];
            });
          }
          return membData;
        });
        return {
          start_date: session.startDate,
          tag_ids: session.tagIds,
          time_zone_name: session.timeZone.value,
          memberships,
        };
      });
      return { sessions: sessionsData };
    },
  },

  mutations: {
    updateRoleIdsForTag(state, { tagId, roleIds }) {
      state.tags.find(tag => tag.id === tagId).roleIds = roleIds;
    },

    addSession(state, { session }) {
      state.sessions.push(session);
    },

    deleteSession(state, { session }) {
      const sessionIdx = state.sessions.findIndex(s => s.id === session.id);
      state.sessions.splice(sessionIdx, 1);
    },

    updateSession(state, { sessionIdx, session }) {
      if (sessionIdx || sessionIdx === 0) {
        state.sessions[sessionIdx] = session;
      } else {
        state.defaultSession = session;
      }
    },

    updateMembership(state, {
     sessionId, roleId, key, value, preserveExistingMembership,
    }) {
      // Find the membership for this session and this role. (If one exists and we want to preserve existing memberships, just return out)
      const session = sessionId ? state.sessions.find(s => s.id === sessionId) : state.defaultSession;
      let membership = session.memberships.find(m => m.role.id === roleId);
      if (membership && (membership.user || membership.role_selection) && preserveExistingMembership) return;
      // If there is no membership for this session and this role, create one
      if (!membership) {
        session.memberships.push({ role: { ...state.roles.find(r => r.id === roleId) } });
        membership = session.memberships.find(m => m.role.id === roleId);
      }
      // Set the value. If setting `user` or `role_selection`, nullify the other
      membership[key] = value;
      if (key === 'user') membership.role_selection = null;
      if (key === 'role_selection') membership.user = null;
    },
  },

  actions: {
    importCsv() {
      // TODO: Obviously this needs to do something
    },

    addSession({ state, commit, dispatch }, { startDate }) {
      const blankSessionFromDefault = _.cloneDeep(state.defaultSession);
      blankSessionFromDefault.id = Random.uuid();
      if (startDate) blankSessionFromDefault.startDate = startDate;
      commit('addSession', { session: blankSessionFromDefault });
      dispatch('applyRoleSelectionSettingsFromNewTags', { sessionId: blankSessionFromDefault.id, previousTagIds: [] });
    },

    updateSession({ state, commit, dispatch }, { id, key, value }) {
      let sessionIdx;
      let oldSession;
      // We're going to deep clone either a session in a row or the defaultSession,
      // make the change to that, and then replace it wholesale in state.
      if (id) {
        sessionIdx = state.sessions.findIndex(s => s.id === id);
        oldSession = _.cloneDeep(state.sessions[sessionIdx]);
      } else {
        // If no id is provided, that means we deep clone and replace the defaultSession
        oldSession = _.cloneDeep(state.defaultSession);
      }
      const newSession = _.cloneDeep(oldSession); // Potentially redundant cloneDeep here, but want to preserve old tagIds
      newSession[key] = value;
      commit('updateSession', { sessionIdx, session: newSession });
      if (id && key === 'tagIds') {
        // If we're making a change to the tagIds value of a session in a row, we may need to
        // apply role selection settings from any new tags.
        const previousTagIds = oldSession.tagIds;
        dispatch('applyRoleSelectionSettingsFromNewTags', { sessionId: id, previousTagIds });
      }
    },

    applyRoleSelectionSettingsFromNewTags({ state, getters, commit }, { sessionId, previousTagIds }) {
      // When changing the tags on a session (or creating a session with tags), we need to account for role selection settings
      // that are baked into that tag. In order to do this, we determine what tags are "new" to this session, iterate through
      // all the role_selection_settings objects in all of them, and apply them to the session (without overwriting existing memberships)
      const session = state.sessions.find(s => s.id === sessionId);
      const newTagIds = session.tagIds.filter(id => !previousTagIds.includes(id));
      const newTagsWithSettings = state.tags.filter(tag => newTagIds.includes(tag.id) && tag.role_selection_settings?.length);
      const newSettings = newTagsWithSettings.flatMap(tag => tag.role_selection_settings);
      newSettings.forEach((setting) => {
        const selectorRecord = setting.selector_role || setting.selector || setting.channel;
        const selectorArgs = getters.roleSelectionArgs(selectorRecord);
        commit('updateMembership', {
          sessionId,
          roleId: setting.selectee_role.id,
          key: 'role_selection',
          value: selectorArgs,
          preserveExistingMembership: true,
        });
      });
    },

    applyUniversalTags({ state, dispatch }, { idsToAdd, idsToRemove }) {
      // We want to apply the changes to both the default and to every session already in rows
      [state.defaultSession, ...state.sessions].forEach((session) => {
        const newIds = [];
        session.tagIds.forEach((id) => {
          if (!idsToRemove.includes(id)) newIds.push(id);
        });
        idsToAdd.forEach((id) => {
          if (!newIds.includes(id)) newIds.push(id);
        });
        dispatch('updateSession', { id: session.id, key: 'tagIds', value: newIds });
      });
    },

    applyUniversalRoleSelection({ state, commit }, { roleId, user, roleSelection, preserveExistingMembership }) {
      const updateArgs = user ? { roleId, key: 'user', value: user } : { roleId, key: 'role_selection', value: roleSelection };
      // First, update the defaultSession with the new selections
      commit('updateMembership', updateArgs);
      // Next apply the role selection to all sessions, overriding or not based on the `override` bool
      state.sessions.forEach((session) => {
        commit('updateMembership', {
          ...updateArgs,
          sessionId: session.id,
          preserveExistingMembership,
        });
      });
    },

    fetchRolesForTag({ state, commit }, { tagId }) {
      // Too expensive to load every involved role for every tag, so load em as we need em
      // First check to see if we've loaded them already, and if we haven't,
      // load them via fetch_roles_as_json and put them in the tag in state.
      const tag = state.tags.find(t => t.id === tagId);
      if (!tag.roleIds) {
        return new Promise((resolve, _reject) => {
          Network.get(`/${tag.route_flow_type}/tags/${tag.id}/fetch_roles_as_json`, {
              success: ({ roleIds }) => {
                commit('updateRoleIdsForTag', { tagId: tag.id, roleIds });
                resolve(true);
              },
            });
        });
      }
    },

    // Form submission

    // TODO: Should I use a name that better implies it's hitting the backend?
    upsertSessions({ state, commit, getters, dispatch }) {
      return new Promise((resolve, _reject) => {
        commit('update', { key: 'formSubmitted', value: true }, { root: true });
        Network.post(
          `${getters.basePath}/manual_enrollment`,
          getters.formData,
          {
            success: (data) => {
              const statusId = data.status_id;
              if (statusId) {
                // No need for an initial trigger, it'll never have finished that fast.
                const intervalId = setInterval(() => {
                  dispatch('fetchUpsertStatus', { statusId, intervalId });
                }, 2000);
              } else {
                dispatch('handleSubmitError');
              }
            },
            error: () => dispatch('handleSubmitError'),
          },
        );
      });
    },

    fetchUpsertStatus({ dispatch, getters }, { statusId, intervalId }) {
      return new Promise((resolve, reject) => {
        Network.get(
          `/onboarding/manual_enrollment/upsert_status/${statusId}`,
          {
            success: (data) => {
              if (data.status === 'success') {
                clearInterval(intervalId);
                window.location = getters.batchesPath;
              } else if (data.status === 'error') {
                // We can replace this with more specific errors, since the backend is passing them up.
                // That said, front-end validations should make it extremely unlikely for this to happen.
                dispatch('handleSubmitError', intervalId);
              }
              // The third status is "processing". If still processing, do nothing (the interval will keep running).
            },
            error: () => dispatch('handleSubmitError', intervalId),
          },
        );
      });
    },

    handleSubmitError({ commit, dispatch }, intervalId) {
      if (intervalId) { clearInterval(intervalId); }
      dispatch('setErrorToast', null, { root: true });
      commit('update', { key: 'formSubmitted', value: false }, { root: true });
    },
  },
};

import Network from 'lib/api/network';
import moment from 'moment';

export default {
  namespaced: true,

  state() {
    return {
      settings: {},
    };
  },

  getters: {
    // When the rate key is true, display the value as a percentage
    statDisplayConstants() {
      return {
        active_users: { name: 'Active Donut users', description: 'Unique users, including Journeys admins and recipients of Donut content, within a specific timeframe', stateStat: true, helpArticle: 'https://help.donut.ai/en/articles/9977435-donut-s-home-page' },
        onboarding_sessions_created: { name: 'New Hires added', description: 'How many New Hires were added to Onboarding Journeys' },
        nonboarding_sessions_created: { name: 'Participants added', description: 'How many Participants were added to non-Onboarding Journeys' },
        active_session_members: { name: 'Users in Journeys', description: 'Unique users who were members of an active Journey', stateStat: true },
        poll_interactions_completed: { name: 'Poll completion rate', description: 'Percentage of polls answered', rate: true, tagFilter: true },
        task_assignments_completed: { name: 'Task completion rate', description: 'Percentage of tasks completed or dismissed', rate: true, tagFilter: true },
        coffee_meetings_completed: { name: 'Intros completed', description: 'How many meetings from Intros channels successfully met' },
        active_programs: { name: 'Active Donut channels', description: 'How many Intros, Watercooler, and Celebrations channels were running in your workspace', stateStat: true },
        celebration_events_sent: { name: 'Celebrations sent', description: 'How many birthdays, anniversaries, and custom dates were celebrated' },
        watercooler_prompt_events_sent: { name: 'Watercooler topics sent', description: 'How many Watercooler topics were sent' },
      };
    },

    dateWindowDisplayConstants() {
      return {
        '7d': 'Last 7 days',
        '30d': 'Last 30 days',
        '90d': 'Last 90 days',
        '6m': 'Last 6 months',
        '1y': 'Last 12 months',
        all_time: 'All time',
      };
    },

    emptyStat() {
      return { stats: [{ data: { complete_count: '-' } }] };
    },

    // This mirrors Stats::Daily::Aggregator::EARLIEST_STATE_STAT_DATE
    earliestStateStatDate() {
      return '2023-01-01';
    },
  },

  actions: {
    fetchSettings({ rootState, commit, dispatch }) {
      const url = `/reporting_module_settings/${rootState.currentUser.id}`;
      Network.get(url, {
        success: (res) => {
          commit('update', { module: 'reportingModules', key: 'settings', value: res.reporting_module_setting }, { root: true });
        },
        error: (_error) => {
          dispatch('setErrorToast', null, { root: true });
        },
      });
    },

    fetchModuleData({ dispatch, getters }, reportingModule) {
      const params = new URLSearchParams(Object.entries(reportingModule));
      const url = `/reporting_module_settings/data?${params.toString()}`;
      return new Promise((resolve, _reject) => {
        Network.get(url, {
          success: (res) => {
            resolve(res);
          },
          error: (_error) => {
            dispatch('setErrorToast', null, { root: true });
            resolve(getters.emptyStat);
          },
        });
      });
    },

    pollForModuleData({ dispatch, getters }, reportingModule) {
      return new Promise((resolve, _reject) => {
        const maxPollCount = 40; // 2 minutes
        let pollCount = 0;
        let polling = setInterval(() => {
          dispatch('fetchModuleData', reportingModule).then((res) => {
            if (res.stats === 'generating_async') {
              pollCount += 1;
              window.mixpanel.track('Reporting Modules - Polling for module', { module: reportingModule, pollCount });
              // Display a warning toast after 10 polls, which is ~30 seconds
              if (pollCount === 10) {
                const toast = {
                  type: 'warning',
                  text: 'We are still generating some reporting data. Please check back in a few minutes. You do not need to reload the page.',
                };
                dispatch('setToast', toast, { root: true });
                window.mixpanel.track('Reporting Modules - Displayed still generating data warning toast', { module: reportingModule, pollCount });
              } else if (pollCount >= maxPollCount) {
                clearInterval(polling);
                polling = null;
                dispatch('setErrorToast', null, { root: true });
                window.mixpanel.track('Reporting Modules - Polling for module failed', { module: reportingModule });
                resolve(getters.emptyStat);
              }
            } else {
              clearInterval(polling);
              polling = null;
              window.mixpanel.track('Reporting Modules - Loaded module', { module: reportingModule });
              resolve(res);
            }
          });
        }, 3000); // Every 3 seconds
      });
    },

    updateModule(
      { state, commit, rootState, getters },
      {
        reportingModule, selectedStatName, selectedDateWindow, selectedStartDate, selectedEndDate, selectedTagId,
      },
    ) {
      // When no change
      if (reportingModule.stat_name === selectedStatName
        && reportingModule.date_window === selectedDateWindow
        && reportingModule.start_date === selectedStartDate
        && reportingModule.end_date === selectedEndDate
        && reportingModule.tag_id === selectedTagId) {
        return;
      }

      const url = `/reporting_module_settings/${rootState.currentUser.id}`;
      const newSettings = { ...state.settings };
      const updatedModule = newSettings.modules.find((mod) => mod === reportingModule);
      updatedModule.stat_name = selectedStatName;
      updatedModule.date_window = selectedDateWindow;

      if (selectedTagId === 'all_tags') {
        delete updatedModule.tag_id;
      } else {
        updatedModule.tag_id = selectedTagId;
      }

      if (selectedDateWindow === 'custom'
        && !selectedStartDate
        && !selectedEndDate
        && !getters.statDisplayConstants[updatedModule.stat_name].stateStat
      ) {
        updatedModule.date_window = 'all_time';
        delete updatedModule.start_date;
        delete updatedModule.end_date;
      } else if (selectedDateWindow === 'custom') {
        delete updatedModule.date_window;

        if (selectedStartDate) {
          updatedModule.start_date = moment(selectedStartDate).format('YYYY-MM-DD');
        } else if (getters.statDisplayConstants[updatedModule.stat_name].stateStat) {
          updatedModule.start_date = getters.earliestStateStatDate;
        } else {
          delete updatedModule.start_date;
        }
        if (selectedEndDate) {
          updatedModule.end_date = moment(selectedEndDate).format('YYYY-MM-DD');
        } else {
          delete updatedModule.end_date;
        }
      } else {
        delete updatedModule.start_date;
        delete updatedModule.end_date;
      }

      const args = { reporting_module_setting: { template: newSettings } };
      Network.put(url, args, {
        success: (_res) => {
          window.mixpanel.track('Reporting Modules - Saved module settings', { oldModule: reportingModule, updatedModule });
          commit('update', { module: 'reportingModules', key: 'settings', value: newSettings }, { root: true });
        },
        error: (_res) => {
          dispatch('setErrorToast', null, { root: true });
        },
      });
    },
  },
};

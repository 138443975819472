export default {
  namespaced: true,

  state() {
    return {
      tags: null,
      defaultTagIds: null,
      defaultHrisTagIds: null,
      mappings: null,
      exclusions: null,
      categories: null,
      enrollmentSource: null,
      disableHrisWarningDisplayed: false,
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    updateMapping(state, { idx, key, value }) {
      state.mappings[idx][key] = value;
    },

    updateExclusion(state, { idx, key, value }) {
      state.exclusions[idx][key] = value;
    },

    addNewMapping(state) {
      state.mappings[state.mappings.length] = {
        hris_type: null,
        hris_value: null,
        category_value: null,
        target_ids: [],
      };
    },

    addNewExclusion(state) {
      state.exclusions[state.exclusions.length] = {
        hris_type: null,
        hris_value: null,
        category_value: null,
      };
    },

    deleteMappingAtIdx(state, { idx }) {
      const firstHalf = state.mappings.slice(0, idx);
      const secondHalf = state.mappings.slice(idx + 1, state.mappings.length);
      state.mappings = firstHalf.concat(secondHalf);
    },

    deleteExclusionAtIdx(state, { idx }) {
      const firstHalf = state.exclusions.slice(0, idx);
      const secondHalf = state.exclusions.slice(idx + 1, state.exclusions.length);
      state.exclusions = firstHalf.concat(secondHalf);
    },

    updateEnrollmentSource(state, newSource) {
      state.enrollmentSource = newSource;
    },
  },

  actions: {
    establishInitialState({ commit }, {
  tags, defaultTagIds, defaultHrisTagIds, mappings, exclusions, categories, enrollmentSource,
  }) {
      commit('update', { key: 'tags', value: tags });
      commit('update', { key: 'defaultTagIds', value: defaultTagIds });
      commit('update', { key: 'defaultHrisTagIds', value: defaultHrisTagIds });
      commit('update', { key: 'mappings', value: mappings });
      commit('update', { key: 'exclusions', value: exclusions });
      commit('update', { key: 'categories', value: categories });
      commit('update', { key: 'enrollmentSource', value: enrollmentSource });
    },

    warnIfDisablingHrisOnboarding({ commit, state, rootState }, newSource) {
      if (rootState.persistedRecord.enrollmentSource === 'auto_enroll_hris' && newSource !== 'auto_enroll_hris' && !state.disableHrisWarningDisplayed) {
        commit('update', { key: 'disableHrisWarningDisplayed', value: true });
        commit('updateModal', {
          visible: true,
          subcomponent: 'onboarding-tags-enrollment-modals-disabling-hris-warning',
          size: 'l',
        }, { root: true });
      }
    },
  },
};
